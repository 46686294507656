import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { createGroupInfo } from '../utils/handleGroupInfo'
import ProcessRichText from '../utils/processRichText'

const EntryStyles = styled.div`
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .image-container {
    margin: 20px auto;
    max-width: 300px;
  }

  .title {
    h1 {
      font-size: 5rem;
    }
    h3 {
      font-style: italic;
    }
  }

  .rich-area {
    margin-bottom: 50px;
  }

  ul {
    list-style: none;
    padding: 0;
    text-align: center;
  }
`

export default function IntChampItem({ data }) {
  const { entry } = data
  createGroupInfo([entry])

  return (
    <EntryStyles className="content-width">
      <div className="content-container">
        <div className="title">
          <h1>{entry.title}</h1>
          <h3>{entry.year} Hall of Fame Entry</h3>
        </div>
        <div className="image-container">
          <Img fluid={entry.image.asset.fluid} alt={entry.image.alt} />
        </div>
        <ul>Chapter: {entry.chapter}</ul>
        <div className="rich-area">
          <ProcessRichText curProp={entry} />
        </div>
      </div>
    </EntryStyles>
  )
}

export const query = graphql`
  query($slug: String) {
    entry: sanityHallOfFame(slug: { current: { eq: $slug } }) {
      content: _rawContent
      id
      title
      year
      chapter
      image {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
